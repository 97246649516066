import * as S from './styled';
import {logo} from "../../assets/images";

function Loading(): JSX.Element {
  return (
    <S.Container>
      <S.SpinningImage src={logo} alt="Loading" />
    </S.Container>
  );
}

export default Loading;
