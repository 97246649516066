import React from "react";
import * as S from "./styled";

interface Props {
  text?: string;
}

const TextRollEmpty = (props: Props) => {
  return (
    <S.Background>
      <S.ContainerText>
        <S.LetterFirst>V</S.LetterFirst>
        <S.Text>
          amos lá, aventureiro, role o d10 e deixe que o acaso guie sua jornada!
          {props.text && props.text}
        </S.Text>
      </S.ContainerText>
    </S.Background>
  );
};

export default TextRollEmpty;
