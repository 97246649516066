import React from "react";
import * as S from "./styled";
import { systems } from "../../global/const";

const GridSystems: React.FC = () => {
  return (
    <S.GridContainer>
      {systems.map((item, index) => (
        <S.LinkRedirect key={index} to={`/sistemas${item.link}`}>
          <S.GridItem>
            <S.Image src={item.image} alt={item.name} />
          </S.GridItem>
        </S.LinkRedirect>
      ))}
    </S.GridContainer>
  );
};

export default GridSystems;
