import styled from "styled-components";

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #333;
`;

export const TableHead = styled.thead``;

export const TableRow = styled.tr``;

export const TableHeader = styled.th`
  background-color: #333;
  color: white;
  padding: 10px;
  text-align: left;
  border: 1px solid black;
`;

export const TitleHeader = styled.th`
  background-color: #d3d3d3;
  padding: 10px;
  font-size: 18px;
  text-align: left;
  border: 1px solid black;
`;

export const TableData = styled.td`
text-align: left;
  padding: 10px;
  border: 1px solid black;
`;
