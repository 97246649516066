import * as S from "./styled";
import { socialMedias } from "../../global/const";

function HeaderAnimation() {
  return (
    <S.HeaderAnimation>
      <S.ContainerIcon>
        {socialMedias.map((SocialMedia, index) => (
          <S.ContainerLink key={index}>
            <S.Link href={SocialMedia.link} target="_blank">
              <S.Icon>
                <SocialMedia.icon />
              </S.Icon>
            </S.Link>
          </S.ContainerLink>
        ))}
      </S.ContainerIcon>
    </S.HeaderAnimation>
  );
}

export default HeaderAnimation;
