import React from "react";
import * as S from "./styled";
import { useLocation } from "react-router-dom";

interface HeaderSystemProps {
  backgroundColor: string;
  linearGradient: string[];
  image: string;
  name: string;
}

const HeaderSystem: React.FC<HeaderSystemProps> = ({
  backgroundColor,
  linearGradient,
  image,
  name,
}) => {

  return (
    <S.Container>
      <S.HeaderAnimation linearGradient={linearGradient} />
      <S.Header backgroundColor={backgroundColor}>
        <S.Navigation>
            <S.ContainerLogo>
              <S.Logo src={image} alt={name} />
            </S.ContainerLogo>
        </S.Navigation>
      </S.Header>
      <S.HeaderAnimation linearGradient={linearGradient} />
    </S.Container>
  );
};

export default HeaderSystem;
