import styled, { keyframes } from "styled-components";

export const AnimationGradient = keyframes`
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
`;

export const HeaderAnimation = styled.header`
  background-size: 200% 200%;
  animation: ${AnimationGradient} 2s linear infinite alternate;
  background-image: linear-gradient(
    -45deg,
    #BEA322,
    #ccb842,
    #d0bf82,
    #f8e374
  );
  width: 100%;
  height: 28px;
`;

export const ContainerLink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const ContainerIcon = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: row;
  gap: 10px;
`;

export const Link = styled.a`
  text-decoration: none;
`;

export const Icon = styled.div`
  padding: 2px;
  cursor: pointer;
  font-size: 1.5rem;
  color: white;
  display: inline-block;

  &:hover {
    color: #931C2A;
  
`;