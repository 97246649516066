import React from "react";
import * as S from "./styled";

interface TableSceneProps {
  title: string;
  location: string;
  character: string;
  event: string;
  hasCharacter: boolean;
}

const TableScene: React.FC<TableSceneProps> = ({
  title,
  location,
  character,
  event,
  hasCharacter,
}) => {
  return (
    <S.Table>
      <S.TableHead>
        <S.TableRow>
          <S.TitleHeader colSpan={3}>{title}</S.TitleHeader>
        </S.TableRow>
        <S.TableRow>
          <S.TableHeader>Local</S.TableHeader>
          <S.TableHeader>
            {hasCharacter ? "Personagem" : "Evento"}
          </S.TableHeader>
        </S.TableRow>
      </S.TableHead>
      <tbody>
        <S.TableRow>
          <S.TableData>{location}</S.TableData>
          <S.TableData>{hasCharacter ? character : event}</S.TableData>
        </S.TableRow>
      </tbody>
    </S.Table>
  );
};

export default TableScene;
