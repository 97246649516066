import styled from "styled-components";

export const Background = styled.div`
    background-color: black;
    padding: 10px;
    border-radius: 2px;
`;

export const ContainerText = styled.div`
display:flex;
`;

export const LetterFirst = styled.p`
  margin: 0;
  padding: 0;
  text-align: left;
  font-family: "Mevno", sans-serif;
  font-size: 30px;
  color: #FFFFFF;
`;

export const Text = styled.p`
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
  font-family: "Augusta", sans-serif;
  font-size: 20px;
  color: #FFFFFF;
`;
