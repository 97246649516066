import {
  FaInstagram,
  FaYoutube,
  FaTiktok,
  FaTwitter,
  FaTwitch,
  FaMedium,
  FaSpotify,
  FaFacebook,
} from "react-icons/fa";
import { IconType } from "react-icons";
import { Ducado_Verona, MorkBorg, Pugmire } from "../assets/images";

interface SocialMedia {
  link: string;
  icon: IconType;
}

interface ListNavigation {
  link: string;
  name: string;
}

interface Systems {
  link: string;
  name: string;
  image: string;
  description: string;
}

export const socialMedias: SocialMedia[] = [
  {
    link: "https://www.instagram.com/tecno_forja_rpg/",
    icon: FaInstagram,
  },
  {
    link: "https://www.youtube.com/@tecno_forja_rpg?sub_confirmation=1",
    icon: FaYoutube,
  },
  {
    link: "https://www.twitch.tv/tecno_forja_rpg",
    icon: FaTwitch,
  },
  {
    link: "https://www.tiktok.com/@tecno_forja_rpg",
    icon: FaTiktok,
  },
  {
    link: "https://www.facebook.com/tecnoforjarpg",
    icon: FaFacebook,
  },
  {
    link: "https://x.com/tecno_forja_rpg",
    icon: FaTwitter,
  },
  {
    link: "https://open.spotify.com/user/31fzqnfgbb2llrhn66crwhaatufq",
    icon: FaSpotify,
  },
  {
    link: "https://medium.com/@tecnoforjarpg",
    icon: FaMedium,
  },
];

export const listNavigation: ListNavigation[] = [
  { link: "/sistemas", name: "Sistemas" },
];

export const systems: Systems[] = [
  {
    link: "/ducadoverona",
    name: "Ducado Verona",
    image: Ducado_Verona,
    description: "",
  },
  {
    link: "/pugmire",
    name: "Pugmire",
    image: Pugmire,
    description: "",
  },
  {
    link: "/morkborg",
    name: "Mork Borg",
    image: MorkBorg,
    description: "",
  },
];
